import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PermissionModule from "@/store/modules/PermissionModule";
import UserModule from "@/store/modules/UserModule";
import ChartsModule from "@/store/modules/ChartsModule";
import SearchofSearch from "@/store/modules/ShareofSearch";
import ProductContentReport from "@/store/modules/ProductContentReport";
import EmailModule from "@/store/modules/EmailModule";
import EanMatchingModule from "@/store/modules/EanMatchingModule";
import SearchKeywordModule from "@/store/modules/SearchKeywordModule";
import PortfolioModule from "@/store/modules/PortfolioModule";
import ImageOptimisationModule from "@/store/modules/ImageOptimisationModule";
import AmazonPortfolioModule from "@/store/modules/AmazonPortfolioModule";
import ResumeableDashboard from "@/store/modules/ResumeableDashboard";
import IncludeKeyword from "@/store/modules/IncludeKeyword";
import ExcludeKeyword from "@/store/modules/ExcludeKeyword";
import MarketModule from "@/store/modules/MarketModule";

config.rawError = true;

const store = createStore({
  state() {
    return {
      includeKeywords: [],
    };
  },
  mutations: {
    setKeyword(state, includeKeyword) {
      state.includeKeywords.push(includeKeyword);
    },
  },
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    PermissionModule,
    UserModule,
    ChartsModule,
    SearchofSearch,
    ProductContentReport,
    EmailModule,
    EanMatchingModule,
    SearchKeywordModule,
    PortfolioModule,
    ImageOptimisationModule,
    AmazonPortfolioModule,
    ResumeableDashboard,
    IncludeKeyword,
    ExcludeKeyword,
    MarketModule,
  },
});

export default store;
