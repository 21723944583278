import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ChartModule extends VuexModule {
  errors = {};
  allStats = {
    xAxis: [],
    yAxis: [],
    pi_chart: [],
  };
  wordsCloudStats = [];
  markets = {};
  retailers = {};
  search_keywords = {};
  brands = {};
  client_brands = {};
  words_cloud_url = "keyword_match_count";
  loading = true;
  reviewdataTableLoading = true;
  searchdataTableLoading = true;
  searchFinalData = [];
  reviewsData = [];
  reviewDataLoading = true;
  searchDataLoading = true;
  reviewServerItemsLength = 0;
  searchServerItemsLength = 0;
  globalChartFilters = {
    brand: "",
    date: "",
    client_brand: "",
    client_brand_flag: "",
    market: "",
    retailer: "",
    sponsered_flag: "",
    search_keyword: "",
    from_date: "",
    to_date: "",
    reviewer_rating: "",
  };

  /**
   * Get current user object
   * @returns User
   */
  get getBarchartStats() {
    const stat = {};
    stat["xAxis"] = this.allStats.xAxis;
    stat["yAxis"] = this.allStats.yAxis;
    return stat;
  }

  get getPiechartStats() {
    const stat = {};
    stat["xAxis"] = this.allStats.xAxis;
    stat["yAxis"] = this.allStats.pi_chart;
    return stat;
  }

  get getWordsCloudStats() {
    return this.wordsCloudStats;
  }

  get getWordsCloudUrl() {
    return this.words_cloud_url;
  }

  get getreviewdataTableLoading() {
    return this.reviewdataTableLoading;
  }
  get getreviewdataLoading() {
    return this.reviewDataLoading;
  }

  get getsearchdataTableLoading() {
    return this.searchdataTableLoading;
  }

  get getsearchDataLoading() {
    return this.searchDataLoading;
  }

  get getreviewsData() {
    return this.reviewsData;
  }
  get getSearchFinalData() {
    return this.searchFinalData;
  }

  get getMarkets() {
    return this.markets;
  }
  get getRetailers() {
    return this.retailers;
  }
  get getSearchKeywords() {
    return this.search_keywords;
  }
  get getBrands() {
    return this.brands;
  }
  get getEcotoneBrands() {
    return this.client_brands;
  }
  get getReviewServerItemsLength() {
    return this.reviewServerItemsLength;
  }
  get getSearchServerItemsLength() {
    return this.searchServerItemsLength;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_STATS](allStats) {
    this.allStats = { ...allStats };
  }

  @Mutation
  [Mutations.SET_GLOBAL_CHARTS_FILTERS](filters) {
    this.globalChartFilters = { ...filters };
  }

  @Mutation
  [Mutations.SET_SEARCH_FINAL_DATA](data) {
    this.searchFinalData = data;
  }

  @Mutation
  [Mutations.SET_REVIEW_DATA](data) {
    this.reviewsData = data;
  }

  @Mutation
  [Mutations.SET_WORDS_CLOUD_STATS](stats) {
    this.wordsCloudStats = { ...stats };
  }

  @Mutation
  [Mutations.SET_WORDS_CLOUD_URL](url) {
    this.words_cloud_url = url;
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_REVIEW_DATATABLE_LOADING_STATUS](status) {
    this.reviewdataTableLoading = status;
  }

  @Mutation
  [Mutations.SET_REVIEW_DATA_LOADING](status) {
    this.reviewDataLoading = status;
  }

  @Mutation
  [Mutations.SET_SEARCH_DATATABLE_LOADING_STATUS](status) {
    this.searchdataTableLoading = status;
  }

  @Mutation
  [Mutations.SET_SEARCH_DATA_LOADING](status) {
    this.searchDataLoading = status;
  }

  @Mutation
  [Mutations.SET_SEARCH_SERVER_ITEMS_LENGTH](length) {
    this.searchServerItemsLength = length;
  }

  @Mutation
  [Mutations.SET_REVIEW_SERVER_ITEMS_LENGTH](length) {
    this.reviewServerItemsLength = length;
  }

  @Mutation
  [Mutations.SET_MARKETS](value) {
    this.markets = value;
  }
  @Mutation
  [Mutations.SET_RETAILERS](value) {
    this.retailers = value;
  }
  @Mutation
  [Mutations.SET_SEARCH_KEYWORDS](value) {
    this.search_keywords = value;
  }
  @Mutation
  [Mutations.SET_BRANDS](value) {
    this.brands = value;
  }
  @Mutation
  [Mutations.SET_client_brandS](value) {
    this.client_brands = value;
  }

  @Action
  [Actions.STATS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/brand_rank_chart", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATS, data.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(
            Mutations.SET_SEARCH_DATATABLE_LOADING_STATUS,
            false
          );
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SEARCH_FILE_CSV](payload) {
    this.context.commit(Mutations.SET_SEARCH_DATA_LOADING, true);
    const page = typeof payload == "undefined" ? 1 : payload.page;
    const rowsPerPage =
      typeof payload == "undefined" ? 10 : payload.rowsPerPage;
    const sortBy = payload && payload.sortBy ? payload.sortBy : "";
    const sortType = payload && payload.sortType ? payload.sortType : "";
    const search_keyword = this.globalChartFilters.search_keyword
      ? this.globalChartFilters.search_keyword
      : "";
    const sponsered_flag = this.globalChartFilters.sponsered_flag
      ? this.globalChartFilters.sponsered_flag
      : "";
    this.globalChartFilters;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get_file(
        `api/export_search_final_output?start&page=${page}&limit=${rowsPerPage}&sortBy=${sortBy}&sortType=${sortType}&brand=${this.globalChartFilters.brand}&date=${this.globalChartFilters.date}&client_brand=${this.globalChartFilters.client_brand}&client_brand_flag=${this.globalChartFilters.client_brand_flag}&market=${this.globalChartFilters.market}&retailer=${this.globalChartFilters.retailer}&search_keyword=${search_keyword}&sponsered_flag=${sponsered_flag}&from_date=${this.globalChartFilters.from_date}&to_date=${this.globalChartFilters.to_date}`,
        "",
        { responseType: "json" }
      )
        .then(({ data }) => {
          const items = data.data.data;
          const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
          const header = Object.keys(items[0]);
          const csv = [
            header.join(","), // header row first
            ...items.map((row) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
            ),
          ].join("\r\n");

          const fileURL = window.URL.createObjectURL(new Blob([csv]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "search.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
          // this.context.commit(
          //   Mutations.SET_SEARCH_FINAL_DATA,
          //   data.data.search_final_output.data
          // );
          this.context.commit(
            Mutations.SET_SEARCH_DATATABLE_LOADING_STATUS,
            false
          );
          // this.context.commit(
          //   Mutations.SET_SEARCH_SERVER_ITEMS_LENGTH,
          //   data.data.search_final_output.total
          // );
          this.context.commit(Mutations.SET_SEARCH_DATA_LOADING, false);
          // return data.data.search_final_output;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SEARCH_FINAL_DATA_REQUEST](payload) {
    this.context.commit(Mutations.SET_SEARCH_DATA_LOADING, true);
    const page = typeof payload == "undefined" ? 1 : payload.page;
    const rowsPerPage =
      typeof payload == "undefined" ? 10 : payload.rowsPerPage;
    const sortBy = payload && payload.sortBy ? payload.sortBy : "";
    const sortType = payload && payload.sortType ? payload.sortType : "";
    const search_keyword = this.globalChartFilters.search_keyword
      ? this.globalChartFilters.search_keyword
      : "";
    const sponsered_flag = this.globalChartFilters.sponsered_flag
      ? this.globalChartFilters.sponsered_flag
      : "";
    this.globalChartFilters;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/search_final_output?start&page=${page}&limit=${rowsPerPage}&sortBy=${sortBy}&sortType=${sortType}&brand=${this.globalChartFilters.brand}&date=${this.globalChartFilters.date}&client_brand=${this.globalChartFilters.client_brand}&client_brand_flag=${this.globalChartFilters.client_brand_flag}&market=${this.globalChartFilters.market}&retailer=${this.globalChartFilters.retailer}&search_keyword=${search_keyword}&sponsered_flag=${sponsered_flag}&from_date=${this.globalChartFilters.from_date}&to_date=${this.globalChartFilters.to_date}`
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SEARCH_FINAL_DATA,
            data.data.search_final_output.data
          );
          this.context.commit(
            Mutations.SET_SEARCH_DATATABLE_LOADING_STATUS,
            false
          );
          this.context.commit(
            Mutations.SET_SEARCH_SERVER_ITEMS_LENGTH,
            data.data.search_final_output.total
          );
          this.context.commit(Mutations.SET_SEARCH_DATA_LOADING, false);
          return data.data.search_final_output;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.REVIEW_DATA_CSV](payload) {
    this.context.commit(Mutations.SET_REVIEW_DATA_LOADING, true);
    const page = typeof payload == "undefined" ? 1 : payload.page;
    const rowsPerPage =
      typeof payload == "undefined" ? 10 : payload.rowsPerPage;
    const sortBy = payload && payload.sortBy ? payload.sortBy : "";
    const sortType = payload && payload.sortType ? payload.sortType : "";
    this.globalChartFilters;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/export_reviews?start&page=${page}&limit=${rowsPerPage}&sortBy=${sortBy}&sortType=${sortType}&brand=${this.globalChartFilters.brand}&date=${this.globalChartFilters.date}&client_brand=${this.globalChartFilters.client_brand}&client_brand_flag=${this.globalChartFilters.client_brand_flag}&market=${this.globalChartFilters.market}&retailer=${this.globalChartFilters.retailer}&reviewer_rating=${this.globalChartFilters.reviewer_rating}&from_date=${this.globalChartFilters.from_date}&to_date=${this.globalChartFilters.to_date}`
      )
        .then(({ data }) => {
          const items = data.data.data;
          const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
          const header = Object.keys(items[0]);
          const csv = [
            header.join(","), // header row first
            ...items.map((row) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
            ),
          ].join("\r\n");

          const fileURL = window.URL.createObjectURL(new Blob([csv]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "review.csv");
          document.body.appendChild(fileLink);

          fileLink.click();
          // this.context.commit(
          //   Mutations.SET_REVIEW_DATA,
          //   data.data.reviews.data
          // );
          this.context.commit(
            Mutations.SET_REVIEW_DATATABLE_LOADING_STATUS,
            false
          );
          // this.context.commit(
          //   Mutations.SET_REVIEW_SERVER_ITEMS_LENGTH,
          //   data.data.reviews.total
          // );
          this.context.commit(Mutations.SET_REVIEW_DATA_LOADING, false);
          // this.reviewServerItemsLength = 100;
          return data.data.reviews;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.REVIEW_DATA](payload) {
    this.context.commit(Mutations.SET_REVIEW_DATA_LOADING, true);
    const page = typeof payload == "undefined" ? 1 : payload.page;
    const rowsPerPage =
      typeof payload == "undefined" ? 10 : payload.rowsPerPage;
    const sortBy = payload && payload.sortBy ? payload.sortBy : "";
    const sortType = payload && payload.sortType ? payload.sortType : "";
    this.globalChartFilters;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/get_reviews?start&page=${page}&limit=${rowsPerPage}&sortBy=${sortBy}&sortType=${sortType}&brand=${this.globalChartFilters.brand}&date=${this.globalChartFilters.date}&client_brand=${this.globalChartFilters.client_brand}&client_brand_flag=${this.globalChartFilters.client_brand_flag}&market=${this.globalChartFilters.market}&retailer=${this.globalChartFilters.retailer}&reviewer_rating=${this.globalChartFilters.reviewer_rating}&from_date=${this.globalChartFilters.from_date}&to_date=${this.globalChartFilters.to_date}`
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_REVIEW_DATA,
            data.data.reviews.data
          );
          this.context.commit(
            Mutations.SET_REVIEW_DATATABLE_LOADING_STATUS,
            false
          );
          this.context.commit(
            Mutations.SET_REVIEW_SERVER_ITEMS_LENGTH,
            data.data.reviews.total
          );
          this.context.commit(Mutations.SET_REVIEW_DATA_LOADING, false);
          // this.reviewServerItemsLength = 100;
          return data.data.reviews;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.WORDS_CLOUD_STAT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/" + this.words_cloud_url, payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_WORDS_CLOUD_STATS, data.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(
            Mutations.SET_REVIEW_DATATABLE_LOADING_STATUS,
            false
          );
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
  // @Action
  // [Actions.STATS](payload) {
  //   console.log("payload => ", payload);
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.get("api/users")
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_STATS, "");
  //         this.context.commit(Mutations.SET_LOADING_STATUS, false);

  //         return data;
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data);
  //       });
  //   } else {
  //     console.log("unauthorize");
  //   }
  // }

  @Action
  [Actions.MARKET]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/markets")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MARKETS, data.data.market);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.RETAILER]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/retailer")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_RETAILERS, data.data.retailer);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.SEARCH_KEYWORD]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/search_keyword")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SEARCH_KEYWORDS,
            data.data.search_keyword
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.BRAND]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/brand")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_BRANDS, data.data.brand);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.client_brand]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/client_brand")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_client_brandS,
            data.data.client_brand
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
