import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  allusers = {};
  updateUser = {};
  loading = true;

  /**
   * Get current user object
   * @returns User
   */
  get getUsers() {
    return this.allusers;
  }

  get getUpdateUser() {
    return this.updateUser;
  }

  get getLoadingStatus() {
    return this.loading;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_USERS](users) {
    this.allusers = { ...users };
  }

  @Mutation
  [Mutations.SET_UPDATE_USER](userData) {
    this.updateUser = { ...userData };
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Action
  [Actions.USERS]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/users")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_USERS, data.data);
          return;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.CREATE_USER](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("register", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.USERS);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_USER_RECORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/update_user", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.USERS);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_USER_STATUS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(
        "api/activate_deactivate_user/" + payload.user_id,
        payload
      )
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_USER](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/delete_user/" + id, {})
        .then(({ data }) => {
          this.context.dispatch(Actions.USERS);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
