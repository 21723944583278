/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import i18n from "@/core/plugins/i18n";
import Permissions from "./mixins/UserPermission.vue";
import ECharts from "vue-echarts";
import { use } from "echarts/core";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import PrimeVue from "primevue/config";
// import './assets/layout/layout.scss';

// import 'primeflex/primeflex.min.css';
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
// import 'primevue/resources/themes/bootstrap4-dark-purple/theme.css';

// import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
// import "primevue/resources/themes/bootstrap4-light-purple/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-blue/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-purple/theme.css"
// import "primevue/resources/themes/md-light-indigo/theme.css"
// import "primevue/resources/themes/md-light-deeppurple/theme.css"
// import "primevue/resources/themes/md-dark-indigo/theme.css"
// import "primevue/resources/themes/md-dark-deeppurple/theme.css"
// import "primevue/resources/themes/mdc-light-indigo/theme.css"
// import "primevue/resources/themes/mdc-light-deeppurple/theme.css"
// import "primevue/resources/themes/mdc-dark-indigo/theme.css"
// import "primevue/resources/themes/mdc-dark-deeppurple/theme.css"
// import "primevue/resources/themes/tailwind-light/theme.css"
// import "primevue/resources/themes/fluent-light/theme.css"
import "primevue/resources/themes/lara-light-indigo/theme.css";
// import "primevue/resources/themes/lara-dark-indigo/theme.css"
// import "primevue/resources/themes/lara-light-purple/theme.css"
// import "primevue/resources/themes/lara-dark-purple/theme.css"
// import "primevue/resources/themes/lara-light-blue/theme.css"
// import "primevue/resources/themes/lara-dark-blue/theme.css"
// import "primevue/resources/themes/lara-light-teal/theme.css"
// import "primevue/resources/themes/lara-dark-teal/theme.css"
// import "primevue/resources/themes/saga-blue/theme.css"
// import "primevue/resources/themes/saga-green/theme.css"
// import "primevue/resources/themes/saga-orange/theme.css"
// import "primevue/resources/themes/saga-purple/theme.css"
// import "primevue/resources/themes/vela-blue/theme.css"
// import "primevue/resources/themes/vela-green/theme.css"
// import "primevue/resources/themes/vela-orange/theme.css"
// import "primevue/resources/themes/vela-purple/theme.css"
// import "primevue/resources/themes/arya-blue/theme.css"
// import "primevue/resources/themes/arya-green/theme.css"
// import "primevue/resources/themes/arya-orange/theme.css"
// import "primevue/resources/themes/arya-purple/theme.css"
// import "primevue/resources/themes/nova/theme.css"
// import "primevue/resources/themes/nova-alt/theme.css"
// import "primevue/resources/themes/nova-accent/theme.css"
// import "primevue/resources/themes/nova-vue/theme.css"
// import "primevue/resources/themes/luna-amber/theme.css"
// import "primevue/resources/themes/luna-blue/theme.css"
// import "primevue/resources/themes/luna-green/theme.css"
// import "primevue/resources/themes/luna-pink/theme.css"
// import "primevue/resources/themes/rhea/theme.css"

// import ECharts modules manually to reduce bundle size
// import { CanvasRenderer } from "echarts/renderers";
// import { BarChart } from "echarts/charts";
// import { GridComponent, TooltipComponent } from "echarts/components";
import "echarts";

import "@/core/plugins/prismjs";

// use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);
const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'medium', zIndex: 2000 });
app.mixin(Permissions);
app.component("v-chart", ECharts);
app.component("EasyDataTable", Vue3EasyDataTable);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(PrimeVue);

app.directive("theme", {
  mounted(el, binding) {
      if (!Number.isInteger(+binding.value)) {
        el.innerText = (binding.value * 100) + "%";
    }
  },
});

app.mount("#app");
