import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ProductContentReport extends VuexModule {
  product_content_report_markets = {};
  product_content_report_brands = {};
  product_content_report_retailers = {};
  product_content_report_search_keywords = {};
  product_content_report_search_ecotone_brands = {};
  loadingStatus = true;
  errors = {};
  product_content_report_data = [];
  total_record = 0;

  get get_product_content_total_records() {
    return this.total_record;
  }

  get get_product_content_report_data() {
    return this.product_content_report_data;
  }
  get get_product_content_report_error() {
    return this.errors;
  }

  get get_product_content_report_loading_status() {
    return this.loadingStatus;
  }

  get get_product_content_report_market() {
    return this.product_content_report_markets;
  }

  get get_product_content_report_brand() {
    return this.product_content_report_brands;
  }

  get get_product_content_report_retailers() {
    return this.product_content_report_retailers;
  }

  get get_product_content_report_search_keywords() {
    return this.product_content_report_search_keywords;
  }

  get get_product_content_report_ecotone_brands() {
    return this.product_content_report_search_ecotone_brands;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_DATA](data) {
    this.product_content_report_data = data;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_LOADING_STATUS](value) {
    this.loadingStatus = value;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_MARKETS](value) {
    this.product_content_report_markets = value;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_BRANDS](value) {
    this.product_content_report_brands = value;
  }
  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_RETAILERS](value) {
    this.product_content_report_retailers = value;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_SEARCH_KEYWORDS](value) {
    this.product_content_report_search_keywords = value;
  }
  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_ECOTONE_BRANDS](value) {
    this.product_content_report_search_ecotone_brands = value;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CONTENT_DATA_RECORD_NUMBER](value) {
    this.total_record = value;
  }

  @Action
  [Actions.PRODUCT_CONTENT_MARKET]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/markets")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_MARKETS,
            data.data.market
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.PRODUCT_CONTENT_BRAND]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/brand")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_BRANDS,
            data.data.market
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.PRODUCT_CONTENT_RETAILER]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/retailer")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_RETAILERS,
            data.data.retailer
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.PRODUCT_CONTENT_SEARCH_KEYWORD]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/search_keyword")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_SEARCH_KEYWORDS,
            data.data.search_keyword
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.PRODUCT_CONTENT_ECOTONE_BRAND]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/ecotone_brand")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ECOTONE_BRANDS,
            data.data.ecotone_brand
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.PRODUCT_CONTENT_REPORT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(
        "api/reports/product_content?page=" + payload.paginator.page,
        payload
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_LOADING_STATUS,
            false
          );
          this.context.commit(Mutations.SET_PRODUCT_CONTENT_DATA, data.data);
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_DATA_RECORD_NUMBER,
            data.meta.total
          );
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_CONTENT_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }
}
