import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  setFileToken = {};
  loading = true;
  setFolderLising = [];
  addFolder = false;

  /**
   * Get current user object
   * @returns User
   */
  get getToken() {
    return this.setFileToken;
  }

  get getLoadingStatus() {
    return this.loading;
  }

  get getFolderListing() {
    return this.setFolderLising;
  }

  get getAddFolder() {
    return this.addFolder;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_FILE_TOKEN](mails) {
    this.setFileToken = mails;
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_FOLDER_LISTING](mails) {
    this.setFolderLising = mails;
  }

  @Mutation
  [Mutations.SET_ADD_FOLDER](status) {
    this.addFolder = status;
  }

  @Action
  [Actions.UPLOAD_INIT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/upload/init", payload)
        .then(({ data }) => {
          console.log(data.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_FILE_TOKEN, data.data);
          // return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPLOAD_FILERES](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/upload", payload)
        .then(({ data }) => {
          console.log(data.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_FILE_TOKEN, data.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  async [Actions.FOLDER_LISTING]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.get("api/list/folder");
        this.context.commit(Mutations.SET_LOADING_STATUS, false);
        this.context.commit(Mutations.SET_FOLDER_LISTING, data);
        this.context.commit(Mutations.SET_ADD_FOLDER, false);
        return data;
      } catch ({ response }) {
        router.push({ name: "sign-in" });
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  async [Actions.CREATE_FOLDER](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/create/folder", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.DELETE_FOLDER](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/delete/folder", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.DELETE_FILE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/delete/file", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.UPDATE_FILE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/update/file", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.Move_FILE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/move/file", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.Download_FILE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await axios.get(
          `api/file/${payload.folderName}/${payload.fileName}`,
          { responseType: "blob" }
        );
        this.context.commit(Mutations.SET_LOADING_STATUS, false);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  async [Actions.DOWNLOAD_ZIP](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await axios.post(`api/file/download/zip`, payload, {
          responseType: "blob",
        });
        this.context.commit(Mutations.SET_LOADING_STATUS, false);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  async [Actions.MOVE_FOLDER](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/move/folder", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  async [Actions.UPLOAD_COMPLETE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/upload/complete", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.SHARE_FILE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.get(`api/file/share/link/${payload}`);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.SHARE_FILE_URL](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.get(`api/get/share/file/${payload}`);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  async [Actions.EDIT_FOLDER](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("api/update/folder", payload);
        this.context.commit(Mutations.SET_LOADING_STATUS, true);
        this.context.commit(Mutations.SET_ADD_FOLDER, true);
        return data;
      } catch ({ response }) {
        this.context.commit(Mutations.SET_ERROR, response);
        return;
      }
    } else {
      console.log("unauthorize");
    }
  }
}
