import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  allKeywords = {};
  updateUser = {};
  loading = true;
  keywordTotal = "";

  /**
   * Get current user object
   * @returns User
   */
  get getKeywords() {
    return this.allKeywords;
  }

  get getKeywordsTotal() {
    return this.keywordTotal;
  }

  get getUpdateUser() {
    return this.updateUser;
  }

  get getLoadingStatus() {
    return this.loading;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_KEYWORDS](mails) {
    this.allKeywords = { ...mails };
  }

  @Mutation
  [Mutations.SET_UPDATE_USER](userData) {
    this.updateUser = { ...userData };
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_KEYWORDS_TOTAL](status) {
    this.keywordTotal = status;
  }

  @Action
  [Actions.KEYWORD_LISTING](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/pilot/search_keyword_list?page=${
          payload?.page === undefined ? 1 : payload?.page
        }&per_page=${
          payload?.per_page === undefined ? 10 : payload?.per_page
        }&sort_column=${
          payload?.sort_column === undefined ? "" : payload?.sort_column
        }&sort_order=${
          payload?.sort_order === undefined ? 1 : payload?.sort_order
        }&search=${
          payload?.search === undefined ? "" : payload?.search
        }&filter_market=${
          payload?.filter_market === undefined ? "" : payload?.filter_market
        }&filter_keyword=${
          payload?.filter_keyword === undefined ? "" : payload?.filter_keyword
        }&filter_product_scrape=${
          payload?.filter_product_scrape === undefined
            ? ""
            : payload?.filter_product_scrape
        }&filter_pagination=${
          payload?.filter_pagination === undefined
            ? ""
            : payload?.filter_pagination
        }&filter_priority_keyword=${
          payload?.filter_priority_keyword === undefined
            ? ""
            : payload?.filter_priority_keyword
        }&filter_keyword_live=${
          payload?.filter_keyword_live === undefined
            ? ""
            : payload?.filter_keyword_live
        }&filter_brand_term=${
          payload?.filter_brand_term === undefined
            ? ""
            : payload?.filter_brand_term
        }`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_KEYWORDS, data.data);
          this.context.commit(Mutations.SET_KEYWORDS_TOTAL, data.meta.total);
          return;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.CREATE_KEYWORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/add_search_keyword", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.KEYWORD_LISTING);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_KEYWORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/update_search_keyword", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.KEYWORD_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_KEYWORD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/delete_search_keyword", data)
        .then(({ data }) => {
          this.context.dispatch(Actions.KEYWORD_LISTING);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.EXPORT_CSV_KEYWORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/keyword/export_amzn_csv", payload.market)
        .then(({ data }) => {
          const replacer = (key, value) => (value === null ? "" : value);
          const header = Object.keys(data.data[0]);
          const csv = [
            header.join(","), // header row first
            ...data.data.map((row) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
            ),
          ].join("\r\n");
          // Create a Blob object from the CSV string
          const blob = new Blob([csv], { type: "text/csv" });

          // Create a temporary URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Create a download link and click it
          const link = document.createElement("a");
          link.href = url;
          link.download = `Search_keyword_${
            payload.market.market
          }_${new Date().getTime()}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Revoke the temporary URL
          URL.revokeObjectURL(url);
          this.context.dispatch(Actions.AMAZONP_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.UPLOAD_CSV_KEYWORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/keyword/import_amzn_csv", payload.csv_file)
        .then(({ data }) => {
          this.context.dispatch(Actions.AMAZONP_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
