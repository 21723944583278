enum Actions {
  //MARKETS
  GET_MARKETS = "getMarkets",
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  // UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SUBMIT_RESET_PASSWORD = "submitResetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  //Roles and permissions
  ROLE = "role",
  CREATE_ROLE = "createRole",
  PERMISSION = "permission",
  CREATE_PERMISSION = "createPermission",
  UPDATE_ROLE = "updateRole",
  DELETE_ROLE = "deleteRole",
  DELETE_PERMISSION = "deletePermission",
  UPDATE_ROLE_RECORD = "updateRoleRecord",
  UPDATE_PERMISSION_RECORD = "updatePermissionRecord",

  //Users
  CREATE_USER = "createUser",
  USERS = "users",
  UPDATE_USER_STATUS = "updateUserStatus",
  DELETE_USER = "deleteUser",
  UPDATE_USER = "updateUser",
  UPDATE_USER_RECORD = "updateUserRecord",

  //Emails
  CREATE_EMAIL = "createEmail",
  EMAIL_LISTING = "emailListing",
  UPDATE_EMAIL = "updateEmailRecord",
  DELETE_EMAIL = "deleteEmail",
  EXPORT_CSV_EMAIL = "uxportEMAILCSV",
  UPLOAD_CSV_EMAIL = "uploadEmailCSV",

  //Search Keywords
  CREATE_KEYWORD = "createKeyword",
  KEYWORD_LISTING = "keywordListing",
  UPDATE_KEYWORD = "keywordUpdate",
  DELETE_KEYWORD = "deleteKeyword",
  EXPORT_CSV_KEYWORD = "uxportKeywordCSV",
  UPLOAD_CSV_KEYWORD = "uploadKeywordCSV",

  //Product
  CREATE_PRODUCT = "createProduct",
  PRODUCT_LISTING = "productListing",
  UPDATE_EAN = "updateEan",
  DELETE_PRODUCT = "deleteProduct",

  // portfolio
  PORTFOLIO_LISTING = "portfolioListing",
  UPDATE_PORTFOLIO = "keywordportfolio",
  DELETE_PORTFOLIO = "deletePortfolio",
  ADD_PORTFOLIO = "addPortfolio",
  EXPORT_CSV_PORTFOLIO = "uxportPortfolioCSV",
  UPLOAD_CSV_PORTFOLIO = "uploadPortfolioCSV",

  //Emails
  CREATE_INCLUDE_KEYWORD = "createIncludeKeyword",
  INCLUDE_KEYWORD_LISTING = "includeKeywordListing",
  UPDATE_INCLUDE_KEYWORD = "updateIncludeKeyword",
  DELETE_INCLUDE_KEYWORD = "deleteIncludeKeyword",
  EXPORT_CSV_INCLUDE_KEYWORD = "uxportIncludeKeywordCSV",
  UPLOAD_CSV_INCLUDE_KEYWORD = "uploadIncludeKeywordCSV",

  //Emails
  CREATE_EXCLUDE_KEYWORD = "createExcludeKeyword",
  EXCLUDE_KEYWORD_LISTING = "excludeKeywordListing",
  UPDATE_EXCLUDE_KEYWORD = "updateExcludeKeyword",
  DELETE_EXCLUDE_KEYWORD = "deleteExcludeKeyword",
  EXPORT_CSV_EXCLUDE_KEYWORD = "uxportExcludeKeywordCSV",
  UPLOAD_CSV_EXCLUDE_KEYWORD = "uploadExcludeKeywordCSV",

  // ImageOpt
  IMAGEO_LISTING = "iamgeOListing",
  UPDATE_IMAGEO = "updateIamgeO",
  DELETE_IMAGEO = "deleteIamgeO",
  ADD_IMAGEO = "addIamgeO",

  // AmazonPortfolio
  AMAZONP_LISTING = "amazonPortfolioListing",
  UPDATE_AMAZONP = "updateAmazonPortfolio",
  DELETE_AMAZONP = "deleteAmazonPortfolio",
  ADD_AMAZONP = "addAmazonPortfolio",
  UPLOAD_CSV = "uploadCSV",
  EXPORT_CSV = "uxportCSV",

  //Dashboard Resumeable
  UPLOAD_INIT = "uploadinit",
  UPLOAD_FILERES = "uploadFileRes",
  FOLDER_LISTING = "folderListing",
  CREATE_FOLDER = "createFolder",
  DELETE_FOLDER = "deleteFolder",
  MOVE_FOLDER = "moveFolder",
  UPLOAD_COMPLETE = "uploadComplete",
  EDIT_FOLDER = "editFolder",
  DELETE_FILE = "deleteFile",
  UPDATE_FILE = "updateFile",
  Move_FILE = "moveFile",
  Download_FILE = "downloadFile",
  SHARE_FILE = "shareFile",
  SHARE_FILE_URL = "shareFileUrl",
  DOWNLOAD_ZIP = "downloadZip",
  //Charts
  STATS = "stats",
  MARKET = "market",
  RETAILER = "retailer",
  SEARCH_KEYWORD = "search_keyword",
  BRAND = "brand",
  client_brand = "client_brand",

  //Words Cloud
  WORDS_CLOUD_STAT = "wordsCloudStat",
  REVIEW_DATA = "reviewData",
  SEARCH_FINAL_DATA_REQUEST = "searchFinalDataRequest",

  // Files
  SEARCH_FILE_CSV = "searchFileCSVDownload",
  REVIEW_DATA_CSV = "ReviewFileCSVDownload",

  // ShareOFSearch
  SHARE_OF_SEARCH_MARKET = "share_of_search_market",
  SHARE_OF_SEARCH_BRAND = "share_of_search_brand",
  SHARE_OF_SEARCH_STATS = "share_of_search_stats",
  SHARE_OF_SEARCH_RETAILER = "share_of_search_retailer",
  SHARE_OF_SEARCH_SEARCH_KEYWORD = "share_of_search_search_keyword",
  SHARE_OF_SEARCH_client_brand = "share_of_search_client_brand",
  SHARE_OF_SEARCH_REPORT = "share_of_search_report",
  // ProductContent
  PRODUCT_CONTENT_MARKET = "product_content_market",
  PRODUCT_CONTENT_BRAND = "product_content_brand",
  PRODUCT_CONTENT_STATS = "product_content_stats",
  PRODUCT_CONTENT_RETAILER = "product_content_retailer",
  PRODUCT_CONTENT_SEARCH_KEYWORD = "product_content_search_keyword",
  PRODUCT_CONTENT_ECOTONE_BRAND = "product_content_ecotone_brand",
  PRODUCT_CONTENT_REPORT = "product_content_report",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  //Roles
  SET_ROLES = "setRoles",
  SET_PERMISSIONS = "setPermissions",
  SET_EDIT_ROLE = "setEditRole",
  //Users
  SET_USERS = "setUsers",
  SET_UPDATE_USER = "setUpdateUser",
  SET_LOADING_STATUS = "setLoadingStatus",
  SET_ADD_FOLDER = "setAddFolder",
  //Emails
  SET_EMAILS = "setEmails",
  SET_EMAIL_TOTAL = "setEmailTotal",

  //Keyword Include
  SET_INCLUDE_KEYWORD = "setKeywordInclude",
  SET_INCLUDE_KEYWORD_TOTAL = "setKeywordIncludeTotal",

  //Keyword Exclude
  SET_EXCLUDE_KEYWORD = "setKeywordExclude",
  SET_EXCLUDE_KEYWORD_TOTAL = "setKeywordExcludeTotal",

  //Keywords
  SET_KEYWORDS = "setKeywords",
  SET_KEYWORDS_TOTAL = "setKeywordsTotal",
  //Products
  SET_PRODUCTS = "setProducts",
  SET_PRODUCT_TOTAL = "setProductTotal",
  //Portofolio
  SET_PORTFOLIO = "setPortfolio",
  SET_PORTFOLIO_TOTAL = "setPortfolioTotal",
  //Portofolio
  SET_IMAGEO = "setPortfolio",
  SET_IMAGEO_TOTAL = "setPortfolioTotal",
  //Portofolio
  SET_AMAZONP = "setPortfolio",
  SET_AMAZONP_TOTAL = "setPortfolioTotal",
  //Resumeable
  SET_FILE_TOKEN = "setfiletoken",
  SET_FOLDER_LISTING = "setfolderlising",
  //Charts
  SET_STATS = "setStats",
  SET_MARKETS = "setMarkets",
  SET_RETAILERS = "setRetailers",
  SET_SEARCH_KEYWORDS = "setSearchKeywords",
  SET_BRANDS = "setbrands",
  SET_client_brandS = "setEcotoneBrands",
  //Words Cloud
  SET_WORDS_CLOUD_STATS = "setWordsCloudStats",
  SET_WORDS_CLOUD_URL = "setWordsCloudUrl",
  SET_SEARCH_FINAL_DATA = "setSearchFinalData",
  SET_REVIEW_DATA = "setReviewData",
  SET_REVIEW_DATATABLE_LOADING_STATUS = "setReviewDatatableLoadingStatus",
  SET_REVIEW_DATA_LOADING = "setReviewDataLoading",
  SET_SEARCH_DATATABLE_LOADING_STATUS = "setSearchDatatableLoadingStatus",
  SET_SEARCH_DATA_LOADING = "setSearchDataLoading",
  SET_GLOBAL_CHARTS_FILTERS = "setGlobalChartsFilters",
  SET_REVIEW_SERVER_ITEMS_LENGTH = "setReviewServerItemsLength",
  SET_SEARCH_SERVER_ITEMS_LENGTH = "setSearchServerItemsLength",
  // ShareOFSearch
  SET_SHARE_OF_SEARCH_MARKETS = "setShareOfSearchMarkets",
  SET_SHARE_OF_SEARCH_BRANDS = "setShareOfSearchBrands",
  SET_SHARE_OF_SEARCH_STATS = "setStats",
  SET_SHARE_OF_SEARCH_RETAILERS = "setRetailers",
  SET_SHARE_OF_SEARCH_SEARCH_KEYWORDS = "setSearchKeywords",
  SET_SHARE_OF_SEARCH_client_brandS = "setEcotoneBrands",
  SET_SHARE_OF_SEARCH_LOADING_STATUS = "shareofsearchloadingstatus",
  SET_SHARE_OF_SEARCH_ERROR = "shareofsearcherror",
  SET_SHARE_OF_SEARCH_DATA = "shareofsearchdata",
  SET_SHARE_OF_SEARCH_DATA_RECORD_NUMBER = "shareofsearchdatatotalrecord",

  // ProductContent
  SET_PRODUCT_CONTENT_MARKETS = "setProductContentMarkets",
  SET_PRODUCT_CONTENT_BRANDS = "setProductContentBrands",
  SET_PRODUCT_CONTENT_STATS = "setProductContentStats",
  SET_PRODUCT_CONTENT_RETAILERS = "setProductContentRetailers",
  SET_PRODUCT_CONTENT_SEARCH_KEYWORDS = "setProductContentSearchKeywords",
  SET_PRODUCT_CONTENT_ECOTONE_BRANDS = "setProductContentEcotoneBrands",
  SET_PRODUCT_CONTENT_LOADING_STATUS = "setProductContentMarketsloadingstatus",
  SET_PRODUCT_CONTENT_ERROR = "setProductContentMarketserror",
  SET_PRODUCT_CONTENT_DATA = "setProductContentMarketsdata",
  SET_PRODUCT_CONTENT_DATA_RECORD_NUMBER = "setProductContentDataRecordNumber",
}

export { Actions, Mutations };
