import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  allPortfolio = {};
  updateUser = {};
  loading = true;
  portfolioTotal = "";

  /**
   * Get current user object
   * @returns User
   */
  get getPortfolio() {
    return this.allPortfolio;
  }

  get getPortfolioTotal() {
    return this.portfolioTotal;
  }

  get getUpdateUser() {
    return this.updateUser;
  }

  get getLoadingStatus() {
    return this.loading;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_PORTFOLIO](mails) {
    this.allPortfolio = { ...mails };
  }

  @Mutation
  [Mutations.SET_UPDATE_USER](userData) {
    this.updateUser = { ...userData };
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_PORTFOLIO_TOTAL](status) {
    this.portfolioTotal = status;
  }

  @Action
  [Actions.PORTFOLIO_LISTING](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/pilot/sku_list?page=${
          payload?.page === undefined ? 1 : payload?.page
        }&per_page=${
          payload?.per_page === undefined ? 10 : payload?.per_page
        }&sort_column=${
          payload?.sort_column === undefined ? "" : payload?.sort_column
        }&sort_order=${
          payload?.sort_order === undefined ? 1 : payload?.sort_order
        }&search=${
          payload?.search === undefined ? "" : payload?.search
        }&filter_live_sku=${
          payload?.filter_live_sku === undefined ? "" : payload?.filter_live_sku
        }&filter_product_url=${
          payload?.filter_product_url === undefined
            ? ""
            : payload?.filter_product_url
        }&filter_market=${
          payload?.filter_market === undefined ? "" : payload?.filter_market
        }&filter_retailer=${
          payload?.filter_retailer === undefined ? "" : payload?.filter_retailer
        }&filter_product_id=${
          payload?.filter_product_id === undefined
            ? ""
            : payload?.filter_product_id
        }&filter_ean=${
          payload?.filter_ean === undefined ? "" : payload?.filter_ean
        }&filter_product_title=${
          payload?.filter_product_title === undefined
            ? ""
            : payload?.filter_product_title
        }&filter_client_brand_flag=${
          payload?.filter_client_brand_flag === undefined
            ? ""
            : payload?.filter_client_brand_flag
        }&filter_brands_of_interest_flag=${
          payload?.filter_brands_of_interest_flag === undefined
            ? ""
            : payload?.filter_brands_of_interest_flag
        }`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_PORTFOLIO, data.data);
          this.context.commit(Mutations.SET_PORTFOLIO_TOTAL, data.meta.total);
          return;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.ADD_PORTFOLIO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/add_sku", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PORTFOLIO_LISTING);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_PORTFOLIO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/update_sku", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PORTFOLIO_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_PORTFOLIO](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/delete_sku", data)
        .then(({ data }) => {
          this.context.dispatch(Actions.PORTFOLIO_LISTING);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.EXPORT_CSV_PORTFOLIO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/sku/export_amzn_csv", payload.market)
        .then(({ data }) => {
          const replacer = (key, value) => (value === null ? "" : value);
          const header = Object.keys(data.data[0]);
          const csv = [
            header.join(","), // header row first
            ...data.data.map((row) =>
              header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(",")
            ),
          ].join("\r\n");
          // Create a Blob object from the CSV string
          const blob = new Blob([csv], { type: "text/csv" });

          // Create a temporary URL for the Blob object
          const url = URL.createObjectURL(blob);

          // Create a download link and click it
          const link = document.createElement("a");
          link.href = url;
          link.download = `SKU_${
            payload.market.market
          }_${new Date().getTime()}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Revoke the temporary URL
          URL.revokeObjectURL(url);
          this.context.dispatch(Actions.AMAZONP_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response);
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.UPLOAD_CSV_PORTFOLIO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/sku/import_amzn_csv", payload.csv_file)
        .then(({ data }) => {
          this.context.dispatch(Actions.AMAZONP_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
