import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  allImageO = {};
  updateUser = {};
  loading = true;
  imageOTotal = "";

  /**
   * Get current user object
   * @returns User
   */
  get getImageO() {
    return this.allImageO;
  }

  get getImageOTotal() {
    return this.imageOTotal;
  }

  get getUpdateUser() {
    return this.updateUser;
  }

  get getLoadingStatus() {
    return this.loading;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_IMAGEO](mails) {
    this.allImageO = { ...mails };
  }

  @Mutation
  [Mutations.SET_UPDATE_USER](userData) {
    this.updateUser = { ...userData };
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_IMAGEO_TOTAL](status) {
    this.imageOTotal = status;
  }

  @Action
  [Actions.IMAGEO_LISTING](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/pilot/image_hero_finel_list?page=${
          payload?.page === undefined ? 1 : payload?.page
        }&per_page=${
          payload?.per_page === undefined ? 10 : payload?.per_page
        }&sort_column=${
          payload?.sort_column === undefined ? "" : payload?.sort_column
        }&sort_order=${
          payload?.sort_order === undefined ? 1 : payload?.sort_order
        }&search=${payload?.search === undefined ? "" : payload?.search}
        &filter_market=${
          payload?.filter_market === undefined ? "" : payload?.filter_market
        }&filter_retailer=${
          payload?.filter_retailer === undefined ? "" : payload?.filter_retailer
        }&filter_brand=${
          payload?.filter_brand === undefined ? "" : payload?.filter_brand
        }&filter_image_id=${
          payload?.filter_image_id === undefined ? "" : payload?.filter_image_id
        }&filter_product_title=${
          payload?.filter_product_title === undefined
            ? ""
            : payload?.filter_product_title
        }&filter_date=${
          payload?.filter_date === undefined ? "" : payload?.filter_date
        }&filter_month=${
          payload?.filter_month === undefined ? "" : payload?.filter_month
        }&filter_year=${
          payload?.filter_year === undefined ? "" : payload?.filter_year
        }&filter_ean=${
          payload?.filter_ean === undefined ? "" : payload?.filter_ean
        }&filter_image_sku=${
          payload?.filter_image_sku === undefined
            ? ""
            : payload?.filter_image_sku
        }`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_IMAGEO, data.data);
          this.context.commit(Mutations.SET_IMAGEO_TOTAL, data.meta.total);
          return;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.ADD_IMAGEO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/add_image_hero_finel", payload.payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.IMAGEO_LISTING, payload.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_IMAGEO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/update_image_hero_finel", payload.payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.IMAGEO_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_IMAGEO](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/delete_image_hero_finel", payload.ids)
        .then(({ data }) => {
          this.context.dispatch(Actions.IMAGEO_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
