import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  name: string;
  // surname: string;
  email: string;
  password: string;
  api_token: string;
  permission: Array<string>;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  permissions = Array;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  get userPermissions() {
    return this.permissions;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    // delete this.user.permissions;
    this.errors = {};
    this.permissions = user.permissions;
    localStorage.setItem("perm", user.permissions);
    if (user.api_token) {
      JwtService.saveToken(user.api_token);
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    localStorage.removeItem("perm");
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        if (data.data == 401) {
          this.context.commit(Mutations.SET_ERROR, data);
          return false;
        } else {
          this.context.commit(Mutations.SET_AUTH, data.data.user);
          return true;
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        if (data.error_code == 422) {
          const err = {};
          err["message"] = data.data.email
            ? data.data.email.toString()
            : "Something went wrong";
          this.context.commit(Mutations.SET_ERROR, err);
          return false;
        } else {
          this.context.commit(Mutations.SET_AUTH, data.data.user);
          return true;
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
        return false;
      });
  }

  @Action
  [Actions.SUBMIT_RESET_PASSWORD](credentials) {
    return ApiService.post("reset-password", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot-password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      const payload = {};
      payload["token"] = JwtService.getToken();
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_AUTH, data);
          if (data.data.status == false) {
            this.context.commit(Mutations.PURGE_AUTH);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
