import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class MarketModule extends VuexModule {
  errors = {};
  loading = true;
  markets = ["ab"];

  get getMarket() {
    return this.markets;
  }

  get getLoadingStatus() {
    return this.loading;
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_MARKETS](markets) {
    this.markets = markets;
  }

  @Action
  [Actions.GET_MARKETS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/all/markets", payload)
        .then(({ data }) => {
          console.log(data.data, "Market Modu]le");
          this.context.commit(Mutations.SET_MARKETS, data.data);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
