import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ShareofSearch extends VuexModule {
  share_of_search_markets = {};
  share_of_search_brands = {};
  share_of_search_retailers = {};
  share_of_search_search_keywords = {};
  share_of_search_search_client_brands = {};
  loadingStatus = true;
  errors = {};
  share_of_search_data = [];
  total_record = 0;

  get get_share_of_search_total_records() {
    return this.total_record;
  }
  get get_share_of_share_data() {
    return this.share_of_search_data;
  }
  get get_share_of_search_error() {
    return this.errors;
  }

  get get_share_of_search_loading_status() {
    return this.loadingStatus;
  }

  get get_share_of_search_market() {
    return this.share_of_search_markets;
  }

  get get_share_of_search_brand() {
    return this.share_of_search_brands;
  }

  get get_share_of_search_retailers() {
    return this.share_of_search_retailers;
  }

  get get_share_of_search_search_keywords() {
    return this.share_of_search_search_keywords;
  }

  get get_share_of_search_client_brands() {
    return this.share_of_search_search_client_brands;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_DATA](data) {
    this.share_of_search_data = data;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_LOADING_STATUS](value) {
    this.loadingStatus = value;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_MARKETS](value) {
    this.share_of_search_markets = value;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_BRANDS](value) {
    this.share_of_search_brands = value;
  }
  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_RETAILERS](value) {
    this.share_of_search_retailers = value;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_SEARCH_KEYWORDS](value) {
    this.share_of_search_search_keywords = value;
  }
  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_client_brandS](value) {
    this.share_of_search_search_client_brands = value;
  }

  @Mutation
  [Mutations.SET_SHARE_OF_SEARCH_DATA_RECORD_NUMBER](value) {
    this.total_record = value;
  }

  @Action
  [Actions.SHARE_OF_SEARCH_MARKET]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/markets")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_MARKETS,
            data.data.market
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SHARE_OF_SEARCH_BRAND]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/brand")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_BRANDS,
            data.data.market
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SHARE_OF_SEARCH_RETAILER]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/retailer")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_RETAILERS,
            data.data.retailer
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }
  @Action
  [Actions.SHARE_OF_SEARCH_SEARCH_KEYWORD]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/search_keyword")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_SEARCH_KEYWORDS,
            data.data.search_keyword
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SHARE_OF_SEARCH_client_brand]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/client_brand")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_client_brandS,
            data.data.client_brand
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.SHARE_OF_SEARCH_REPORT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(
        "api/reports/share_of_search?page=" + payload.paginator.page,
        payload
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_LOADING_STATUS,
            false
          );
          this.context.commit(Mutations.SET_SHARE_OF_SEARCH_DATA, data.data);
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_DATA_RECORD_NUMBER,
            data.meta.total
          );
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SHARE_OF_SEARCH_ERROR,
            response.data
          );
        });
    } else {
      console.log("unauthorize");
    }
  }
}
