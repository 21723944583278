import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class PermissionModule extends VuexModule {
  errors = {};
  roles = {};
  permissions = {};
  editRole = {};

  /**
   * Get current user object
   * @returns User
   */
  get getRoles() {
    return this.roles;
  }

  get getPermissions() {
    return this.permissions;
  }

  get getEditRole() {
    return this.editRole;
  }

  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_ROLES](roles) {
    this.roles = { ...roles };
  }

  @Mutation
  [Mutations.SET_PERMISSIONS](permissions) {
    this.permissions = { ...permissions };
  }

  @Mutation
  [Mutations.SET_EDIT_ROLE](roleData) {
    this.editRole = { ...roleData };
  }

  @Action
  [Actions.ROLE]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/roles")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_ROLES, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.PERMISSION]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("api/permissions")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_PERMISSIONS, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.CREATE_ROLE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/roles", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_ROLES, data);
          this.context.dispatch(Actions.ROLE);
          this.context.dispatch(Actions.USERS);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.CREATE_PERMISSION](payload) {
    payload.guard_name = "api";
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/permissions", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PERMISSION);
          this.context.dispatch(Actions.USERS);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_ROLE_RECORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/roles/" + payload.id, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.ROLE);
          const tempObj = data.data.permissions.map((item) => item.name);
          this.context.commit(Mutations.SET_AUTH, { permissions: tempObj });
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_PERMISSION_RECORD](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/update/permissions/" + payload.id, payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PERMISSION);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_ROLE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/roles/" + id + "/delete", {})
        .then(({ data }) => {
          this.context.dispatch(Actions.ROLE);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_PERMISSION](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/delete/permissions/" + id, {})
        .then(({ data }) => {
          this.context.dispatch(Actions.PERMISSION);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
