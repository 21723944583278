<script>
export default {
  computed: {
    permissions() {
      return localStorage.getItem("perm");
    },
  },
  methods: {
    $can(permissionName) {
      return this.permissions?.indexOf(permissionName) !== -1;
    },
  },
};
</script>
