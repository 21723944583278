import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class UserModule extends VuexModule {
  errors = {};
  allProducts = {};
  updateUser = {};
  loading = true;
  productTotal = "";

  /**
   * Get current user object
   * @returns User
   */
  get getProducts() {
    return this.allProducts;
  }

  get getProductTotal() {
    return this.productTotal;
  }

  get getUpdateUser() {
    return this.updateUser;
  }

  get getLoadingStatus() {
    return this.loading;
  }
  /**
   * Get authentification errors
   * @returns array
   */

  @Mutation
  [Mutations.SET_PRODUCTS](products) {
    this.allProducts = { ...products };
  }

  @Mutation
  [Mutations.SET_UPDATE_USER](userData) {
    this.updateUser = { ...userData };
  }

  @Mutation
  [Mutations.SET_LOADING_STATUS](status) {
    this.loading = status;
  }

  @Mutation
  [Mutations.SET_PRODUCT_TOTAL](status) {
    this.productTotal = status;
  }

  @Action
  [Actions.PRODUCT_LISTING](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        `api/pilot/product_match_list?page=${
          payload?.page === undefined ? 1 : payload?.page
        }&per_page=${
          payload?.per_page === undefined ? 10 : payload?.per_page
        }&sort_column=${
          payload?.sort_column === undefined ? "" : payload?.sort_column
        }&sort_order=${
          payload?.sort_order === undefined ? 1 : payload?.sort_order
        }&search=${payload?.search === undefined ? "" : payload?.search}
        &filter_product_id=${
          payload?.filter_product_id === undefined
            ? ""
            : payload?.filter_product_id
        }
        &filter_product_url=${
          payload?.filter_product_url === undefined
            ? ""
            : payload?.filter_product_url
        }
        &filter_sap_title=${
          payload?.filter_sap_title === undefined
            ? ""
            : payload?.filter_sap_title
        }
        &filter_ean=${
          payload?.filter_ean === undefined ? "" : payload?.filter_ean
        }`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOADING_STATUS, false);
          this.context.commit(Mutations.SET_PRODUCTS, data.data);
          this.context.commit(Mutations.SET_PRODUCT_TOTAL, data.meta.total);
          return;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          return;
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.CREATE_PRODUCT](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/add_product_match", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PRODUCT_LISTING);
          this.context.commit(Mutations.SET_LOADING_STATUS, true);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.UPDATE_EAN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/update_product_match", payload)
        .then(({ data }) => {
          this.context.dispatch(Actions.PRODUCT_LISTING, payload.data);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }

  @Action
  [Actions.DELETE_PRODUCT](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("api/pilot/delete_product_match", data)
        .then(({ data }) => {
          this.context.dispatch(Actions.PRODUCT_LISTING);
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
        });
    } else {
      console.log("unauthorize");
    }
  }
}
