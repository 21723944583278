<template>
  <router-view />
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onMounted,
  computed,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();
    const error = computed(() => {
      return store.getters.getErrors;
    });
    watchEffect(() => {
      if (error.value.message) {
        // Swal.fire(error.value.message, "", "error");
        Swal.fire({
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
          icon: "error",
          title: "Error",
          titleText: error.value.message,
        });
        if (error.value.message == "Unauthenticated.") {
          store.commit(Mutations.SET_ERROR, {});
          store
            .dispatch(Actions.LOGOUT)
            .then(() => router.push({ name: "sign-in" }));
        }
      }
    });
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
    return {
      error,
    };
  },
});
</script>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/dist/index.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";

//RTL version styles
//@import "assets/css/style.rtl.css";

.background-dark {
  background-color: #1a1a27 !important;
}
</style>
